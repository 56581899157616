import React from "react";
import { inject, observer } from "mobx-react";
import { autobind } from "react-decoration";
import SearchBlock from "./SearchBlock";
import AdvanceSearchBlock from "./AdvanceSearchBlock";
import gql from "graphql-tag";
import client from "lib/ApolloClient";

@inject("appStore")
@observer
class SdiSetDisplayComp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchType: "simple",
      key: ""
    };
  }

  @autobind
  changeTab(searchType, e) {
    this.setState({
      searchType: searchType,
    });
  }

  render() {
    let { searchType } = this.state;
    let { t } = this.props;
    return (
      <>
        <div className="tab_grp">
          <button
            type="button"
            tabIndex="0"
            class={`btn ${searchType === "simple" ? "active" : ""}`}
            onClick={this.changeTab.bind(this, "simple")}
          >
            {t("jumper.common.simplesearch")}
          </button>
          <button
            type="button"
            tabIndex="0"
            class={`btn ${searchType === "advanced" ? "active" : ""}`}
            onClick={this.changeTab.bind(this, "advanced")}
          >
            {t("jumper.common.advsearch")}
          </button>
        </div>
          {searchType === "simple" && <SearchBlock {...this.props} />}
          {searchType === "advanced" && <AdvanceSearchBlock {...this.props} />}
      </>
    );
  }
}

export default SdiSetDisplayComp;
