import React from "react";
import { autobind } from "react-decoration";
import { navigate } from "gatsby";
import qs from "query-string";
import client from "lib/ApolloClient";
import gql from "graphql-tag";
import { inject, observer } from "mobx-react";

@inject("appStore")
@observer
class CustListTab extends React.Component {
  constructor(props) {
    super(props);

    let { globalConfig } = props.appStore;
    this.state = {
      showTabs: false,
      showBookmark: globalConfig["jumper.common.isMyCollection"] !== "0",
      showResources: globalConfig["jumper.common.isresource"] === "1",
      showSDI: globalConfig["jumper.common.search.isSdi"] === "1",
    };
  }

  @autobind
  changePageType(pageType, e) {
    e.preventDefault();
    let { search, location } = this.props;
    let params = {};
    params["pageType"] = pageType;
    navigate([location.pathname, qs.stringify(params)].join("?"));
    if (this.state.showTabs) {
      this.setState({
        showTabs: false,
      });
    }
  }

  @autobind
  handleShowTabs(e) {
    e.preventDefault();
    this.setState({
      showTabs: !this.state.showTabs,
    });
  }

  render() {
    let { searchForm, appStore, t } = this.props;
    let { pageType } = searchForm;
    let { showTabs, showBookmark, showResources, showSDI } = this.state;
    return (
      <>
        <div className="main_tabSet">
          <div className="container">
            <button
              type="button"
              className="active_m"
              onClick={this.handleShowTabs}>
              {showBookmark && pageType === "bookmark" && (
                <>{t("jumperrwd.common.myCollection")}</>
              )}
              {showResources && pageType === "resources" && (
                <>{t("jumperrwd.common.myResourceList")}</>
              )}
              {pageType === "history" && (
                <>{t("jumperrwd.common.searchHistory")}</>
              )}
              {showSDI && pageType === "sdi" && (
                <>{t("jumperrwd.common.sdi")}</>
              )}
            </button>
            <ul className="tabs" style={{ display: showTabs ? "block" : "" }}>
              {showBookmark && (
                <li className={pageType === "bookmark" ? "active" : ""}>
                  <a
                    tabIndex="0"
                    onClick={this.changePageType.bind(this, "bookmark")}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        this.changePageType("bookmark", e);
                      }
                    }}>
                    {t("jumperrwd.common.myCollection")}
                  </a>
                </li>
              )}
              {showResources && (
                <li className={pageType === "resources" ? "active" : ""}>
                  <a
                    tabIndex="0"
                    onClick={this.changePageType.bind(this, "resources")}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        this.changePageType("resources", e);
                      }
                    }}>
                    {t("jumperrwd.common.myResourceList")}
                  </a>
                </li>
              )}
              <li className={pageType === "history" ? "active" : ""}>
                <a
                  tabIndex="0"
                  onClick={this.changePageType.bind(this, "history")}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.changePageType("history", e);
                    }
                  }}>
                  {t("jumperrwd.common.searchHistory")}
                </a>
              </li>
              {showSDI && (
                <li className={pageType === "sdi" ? "active" : ""}>
                  <a
                    tabIndex="0"
                    onClick={this.changePageType.bind(this, "sdi")}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        this.changePageType("sdi", e);
                      }
                    }}>
                    {t("jumperrwd.common.sdi")}
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
      </>
    );
  }
}

export default CustListTab;
