import React from "react";
import { ApolloProvider, Query } from "react-apollo";
import client from "lib/ApolloClient";
import ReactLoading from "react-loading";
import gql from "graphql-tag";
import Link from "lib/Link";

const getJournalFullTextItemList = gql`
  query getJournalFullTextItemList($searchForm: SearchForm) {
    getJournalFullTextItemList(Input: $searchForm) {
      journalID
      url
      resourceID
      ebName
      EnEbName
      ftStartDate
      ftEndDate
      pbulisherSN
      publisherName
    }
  }
`;

class JournalFullTextItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { journalID, variables, language, t, handleClick } = this.props;
    let { searchForm } = variables;
    let { resourceType } = searchForm;
    return (
      <>
        <ApolloProvider client={client.jumperrwdClient}>
          <Query
            query={getJournalFullTextItemList}
            fetchPolicy="network-only"
            variables={{
              searchForm: {
                id: journalID,
                resourceType: resourceType,
                language: language,
              },
            }}>
            {({ loading, error, data, refetch }) => {
              if (loading)
                return (
                  <center>
                    <ReactLoading
                      type="cylon"
                      height={"10%"}
                      width={"20%"}
                      color="#005D98"
                    />
                  </center>
                );
              if (error) return `Error!${error.message}`;
              if (this.state.refetch === null) {
                this.setState({ refetch: refetch });
              }
              if (data.getJournalFullTextItemList) {
                let itemList = data.getJournalFullTextItemList;
                let out = [];
                itemList.map((item) => {
                  let {
                    journalID,
                    url,
                    resourceID,
                    ebName,
                    EnEbName,
                    ftStartDate,
                    ftEndDate,
                    pbulisherSN,
                    publisherName,
                  } = item;
                  let endDate = "";
                  if (ftEndDate.toLowerCase() === "current") {
                    endDate = t("jumper.common.current");
                  } else {
                    endDate = ftEndDate;
                  }
                  let pName = "";
                  if (pbulisherSN !== 0) {
                    pName = publisherName + "-";
                  }
                  let journalURL = "#";
                  if (url !== "") {
                    journalURL =
                      "/sendURLApiV3?mode=click&type=0&journal_id=" + journalID;
                  }
                  out.push(
                    <li>
                      <div className="title">
                        {t("jumper.common.fulltext")}：
                      </div>
                      <div>
                        {ftStartDate !== "" && endDate !== "" && (
                          <>
                            {ftStartDate}-{endDate}
                          </>
                        )}
                        {" " + t("jumperrwd.fulltext.included") + " "}
                        {pName}
                        <a
                          tabIndex="0"
                          onClick={(e) => {
                            e.preventDefault();
                            handleClick("databases", {
                              id: resourceID,
                            });
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleClick("databases", {
                                id: resourceID,
                              });
                            }
                          }}
                          target={journalURL === "#" ? "" : "_blank"}
                          title={journalURL === "#" ? "" : "另開新視窗"}>
                          {language === "en" && EnEbName !== ""
                            ? EnEbName
                            : ebName}
                        </a>
                        <button type="button" className="btn_normal">
                          <Link
                            href={journalURL}
                            target={journalURL === "#" ? "" : "_blank"}
                            title={journalURL === "#" ? "" : "另開新視窗"}>
                            {resourceType !== "ebook"
                              ? t("jumperrwd.common.journalLink")
                              : t("jumperrwd.common.ebookLink")}
                          </Link>
                        </button>
                      </div>
                    </li>
                  );
                });
                return out;
              }
              return "";
            }}
          </Query>
        </ApolloProvider>
      </>
    );
  }
}

export default JournalFullTextItem;
