import React from "react";
import { autobind } from "react-decoration";
import { ApolloProvider, Query, Mutation } from "react-apollo";
import client from "lib/ApolloClient";
import gql from "graphql-tag";
import { inject, observer } from "mobx-react";
import moment from "moment";
import ResultViewLightBox from "components/sdi/ResultViewLightBox";

const getSdiList = gql`
  query getSdiList($sdiType: String) {
    getSdiList(sdiType: $sdiType) {
      sn
      schname
      schnameCovert
    }
  }
`;

const getSdiDetail = gql`
  query getSdiDetail($sdiType: String, $schname: String) {
    getSdiDetail(sdiType: $sdiType, schname: $schname) {
      sn
      schname
      schnameCovert
      circle
      effectDate
      mail
      dblist {
        dbid
        ebname
        id
        schdbid
      }
    }
  }
`;

const deleteSdi = gql`
  mutation deleteSdi($sdiType: String, $schname: String) {
    deleteSdi(sdiType: $sdiType, schname: $schname) {
      success
      message
    }
  }
`;

class ManageModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      display: props.visible ? "block" : "none",
      schname: "",
      sn: "",
    };
  }

  componentWillReceiveProps(props) {
    if (props.visible !== this.props.visible) {
      this.setState({ display: props.visible ? "block" : "none" });
    }
  }

  @autobind
  openResultViewLightBox(e) {
    e.preventDefault();
    this.resultViewLightBox.popup();
  }

  render() {
    var { props } = this;
    let { t } = this.props;
    return (
      <>
        <div
          className="resource_lightbox"
          style={{ display: this.state.display }}>
          <div className="overlay" />
          <div className="resourceblock">
            <div
              className="close"
              tabIndex="0"
              onClick={this.props.close}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  this.props.close(e);
                }
              }}
            />
            <h3>{t("jumperrwd.sdi.manageSdi")}</h3>
            <div className="scroll_content">
              <div className="table_list">
                <table className="table_sprite">
                  <thead>
                    <tr>
                      <th width="10%">{t("jumperrwd.sdi.seq")}</th>
                      <th width="65%">{t("jumperrwd.sdi.strategy")}</th>
                      <th width="25%">{t("jumperrwd.common.function")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.sdilist.map((item, i) => {
                      let { sn, schname, schnameCovert } = item;
                      let schnameParam = "";
                      if (this.props.sdiType == "eds") {
                        schnameParam = sn.toString();
                      } else {
                        schnameParam = schname;
                      }
                      return (
                        <>
                          <tr>
                            <td data-title={t("jumperrwd.sdi.seq")}>{i + 1}</td>
                            <td data-title={t("jumperrwd.sdi.strategy")}>
                              {schnameCovert}
                            </td>
                            <td data-title={t("jumperrwd.common.function")}>
                              <button
                                tabIndex="0"
                                className="btn-submit"
                                onClick={(e) => {
                                  this.setState({
                                    schname: schname,
                                    sn: sn.toString(),
                                  });
                                  this.openResultViewLightBox(e);
                                }}>
                                {t("jumperrwd.sdi.viewResult")}
                              </button>
                              <Mutation mutation={deleteSdi}>
                                {(deleteSdi) => (
                                  <button
                                    type="button"
                                    tabIndex="0"
                                    className="btn-del"
                                    onClick={() => {
                                      if (
                                        window.confirm(
                                          t("jumperrwd.sdi.confirmDeleteSDI", {
                                            sdi: schnameCovert,
                                          })
                                        )
                                      ) {
                                        deleteSdi({
                                          variables: {
                                            sdiType: this.props.sdiType,
                                            schname: schnameParam,
                                          },
                                        }).then((res) => {
                                          if (res.data.deleteSdi.success) {
                                            alert(
                                              t(
                                                "jumperrwd.common.deleteSuccess"
                                              )
                                            );
                                            this.props.refetch();
                                          } else {
                                            alert(
                                              t(
                                                "jumperrwd.common.deleteFailed"
                                              ) +
                                                " " +
                                                res.data.deleteSdi.message
                                            );
                                          }
                                        });
                                      }
                                    }}>
                                    {t("jumperrwd.common.delete")}
                                  </button>
                                )}
                              </Mutation>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="btn_grp">
              <button
                tabIndex="0"
                className="btn btn-submit"
                onClick={this.props.close}>
                {t("jumperrwd.common.close")}
              </button>
            </div>
          </div>
        </div>
        <ResultViewLightBox
          ref={(c) => {
            this.resultViewLightBox = c;
          }}
          schname={this.state.schname}
          sn={this.state.sn}
          {...props}
          title={t("jumperrwd.sdi.viewResult")}
        />
      </>
    );
  }
}

class Modal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      display: props.visible ? "block" : "none",
    };
  }

  componentWillReceiveProps(props) {
    if (props.visible !== this.props.visible) {
      this.setState({ display: props.visible ? "block" : "none" });
    }
  }

  render() {
    let { t } = this.props;
    return (
      <div
        className="resource_lightbox"
        style={{ display: this.state.display }}>
        <div className="overlay" />
        <div className="resourceblock">
          <div
            className="close"
            tabIndex="0"
            onClick={this.props.close}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                this.props.close(e);
              }
            }}
          />
          <h3>{t("jumperrwd.common.detail")}</h3>
          <div className="scroll_content">
            <ul className="li_block">
              <Query
                query={getSdiDetail}
                variables={{
                  sdiType: this.props.sdiType,
                  schname: this.props.schname,
                }}>
                {({ loading, data, refetch, error }) => {
                  if (error) return "";
                  if (loading) return "Loading...";

                  if (data != null) {
                    let { schnameCovert, circle, effectDate, mail } =
                      data.getSdiDetail;
                    return (
                      <>
                        <li>
                          <b>{t("hyint.admin.common.table.phrase")}：</b>
                          {schnameCovert}
                        </li>
                        <li>
                          <b>{t("hyint.customise.searchcycle")}：</b>
                          {circle}
                          {t("jumperrwd.sdi.day")}
                        </li>
                        <li>
                          <b>{t("hyint.admin.common.table.vailddate")}：</b>
                          {moment(effectDate, "YYYYMMDD").format("YYYY-MM-DD")}
                        </li>
                        <li>
                          <b>{t("hyint.customise.mail")}：</b>
                          {mail}
                        </li>
                        {this.props.sdiType === "hyint" &&
                          data.getSdiDetail.dblist !== null && (
                            <>
                              <br />
                              <li>
                                <b>{t("jumperrwd.sdi.selectedDatabases")}：</b>
                              </li>
                              <li>
                                <ul style={{ paddingLeft: "20px" }}>
                                  {data.getSdiDetail.dblist.map((item) => {
                                    return (
                                      <li>
                                        <a>{item.ebname}</a>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </li>
                            </>
                          )}
                      </>
                    );
                  }
                }}
              </Query>
            </ul>
          </div>
          <div className="btn_grp">
            <button
              className="btn btn-submit"
              tabIndex="0"
              onClick={this.props.close}>
              {t("jumperrwd.common.close")}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

@inject("appStore")
@observer
class SdiQueryGroupComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      manageModalVisible: false,
      modalVisible: false,
      schname: "",
    };
  }

  render() {
    let { t } = this.props;
    return (
      <>
        <ApolloProvider client={client.jumperrwdClient}>
          <Query query={getSdiList} variables={{ sdiType: this.props.sdiType }}>
            {({ loading, data, refetch, error }) => {
              if (error) return "";
              if (loading) return "Loading...";

              if (data.getSdiList != null) {
                this.props.getRefetch(refetch);
                return (
                  <>
                    <div
                      className={
                        this.props.showFilter
                          ? "filter_search show_up"
                          : "filter_search"
                      }
                    >
                      <h2>{t("jumper.common.sdi.sdilist")}</h2>
                      <div className="sort_list">
                        <ul>
                          <li>
                            <div className="accordion_content">
                              <ul>
                                {data.getSdiList.map((item, i) => {
                                  let { sn, schname, schnameCovert } = item;

                                  return (
                                    <li>
                                      <a
                                        tabIndex="0"
                                        onClick={() =>
                                          this.setState({
                                            modalVisible: true,
                                            schname: schname,
                                          })
                                        }
                                        onKeyDown={(e) => {
                                          if (e.key === "Enter") {
                                            this.setState({
                                              modalVisible: true,
                                              schname: schname,
                                            });
                                          }
                                        }}
                                      >
                                        {schnameCovert}
                                      </a>
                                    </li>
                                  );
                                })}
                              </ul>
                              <button
                                type="button"
                                tabIndex="0"
                                onClick={() =>
                                  this.setState({ manageModalVisible: true })
                                }
                                className="btn_manage"
                              >
                                {t("jumperrwd.sdi.manageSdi")}
                              </button>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <ManageModal
                      visible={this.state.manageModalVisible}
                      refetch={refetch}
                      close={() => this.setState({ manageModalVisible: false })}
                      sdilist={data.getSdiList}
                      {...this.props}
                    />
                    <Modal
                      visible={this.state.modalVisible}
                      refetch={refetch}
                      close={() => this.setState({ modalVisible: false })}
                      sdiType={this.props.sdiType}
                      schname={this.state.schname}
                      t={this.props.t}
                    />
                  </>
                );
              }
            }}
          </Query>
        </ApolloProvider>
      </>
    );
  }
}

export default SdiQueryGroupComp;
