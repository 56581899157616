import React from "react";
import PropTypes from "prop-types";
import { withI18next } from "lib/withI18next";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { autobind } from "react-decoration";
import ReactHtmlParser from "react-html-parser";

const getHyintList = gql`
  query getHyintList($key: String) {
    getHyintList(key: $key) {
      total
      data {
        id
        ebname
      }
    }
  }
`;

@withI18next(["common"])
class HyintDBListComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      keywords: "",
      display: props.visible ? "block" : "none",
      tempdblist: [],
    };

    this.keywordsRef = React.createRef();
  }

  componentWillReceiveProps(props) {
    if (props.visible !== this.props.visible) {
      this.setState({ display: props.visible ? "block" : "none" });
    }
    if (props.dblist !== this.props.dblist) {
      this.setState({ tempdblist: props.dblist });
    }
  }

  @autobind
  handleChecked(e) {
    let checked = e.target.checked;
    let arr = e.target.value.split("_");

    if (checked) {
      this.setState({
        tempdblist: this.state.tempdblist.concat({
          id: arr[0],
          ebname: arr[1],
        }),
      });
    } else {
      this.setState({
        tempdblist: this.state.tempdblist.filter((item) => item.id !== arr[0]),
      });
    }
  }

  @autobind
  handleAllChecked(list, e) {
    var list1 = [];
    list.map((item) => {
      list1.push({ id: item.id, ebname: item.ebname });

      document.querySelectorAll("#" + item.id).forEach((e) => {
        e.checked = true;
      });
    });

    this.setState({
      tempdblist: list1,
    });
  }

  @autobind
  handleClearChecked() {
    this.setState({ tempdblist: [] });
  }

  render() {
    let { t } = this.props;
    return (
      <>
        <div
          className="resource_lightbox"
          style={{ display: this.state.display }}>
          <div className="overlay"></div>
          <div className="resourceblock">
            <div
              className="close"
              tabIndex="0"
              onClick={(e) => {
                this.props.close(e);
                this.setState({ keywords: "" });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  this.props.close(e);
                  this.setState({ keywords: "" });
                }
              }}
            />
            <Query
              query={getHyintList}
              variables={{ key: this.state.keywords }}>
              {({ loading, data, refetch, error }) => {
                if (error) return "";
                if (loading) return "Loading...";

                if (data != null) {
                  let { total, data: list } = data.getHyintList;

                  return (
                    <>
                      <h3>
                        {t("hyint.customise.databaseslist")}
                        <span>
                          {ReactHtmlParser(
                            t("jumperrwd.list.totalAmount", {
                              total: total,
                            })
                          )}
                        </span>
                      </h3>
                      <div className="keyword ">
                        <div className="form_grp form_inline">
                          <label htmlFor="keywords">
                            {t("hyint.common.table.esourceName")}：
                          </label>
                          <input
                            name="keywords"
                            id="keywords"
                            type="text"
                            placeholder={t("jumperrwd.common.enterKeyword")}
                            accesskey="S"
                            ref={this.keywordsRef}
                          />
                          <input
                            type="submit"
                            tabIndex="0"
                            title={t("jumper.common.search")}
                            value={t("jumper.common.search")}
                            onClick={(e) => {
                              this.setState({
                                keywords: this.keywordsRef.current.value,
                              });
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                this.setState({
                                  keywords: this.keywordsRef.current.value,
                                });
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="tab-content">
                        <div className="btn_grp">
                          <button
                            type="button"
                            tabIndex="0"
                            className="btn"
                            onClick={this.handleAllChecked.bind(this, list)}>
                            {t("jumperrwd.common.selectAll")}
                          </button>
                          <button
                            type="reset"
                            tabIndex="0"
                            className="btn"
                            onClick={this.handleClearChecked}>
                            {t("jumperrwd.common.clear")}
                          </button>
                        </div>
                        <div className="scroll_content">
                          <ul>
                            {list.map((item) => {
                              var checked = false;
                              this.state.tempdblist.map((t) => {
                                if (t.id == item.id) checked = true;
                              });
                              return (
                                <>
                                  <li>
                                    <input
                                      id={item.id}
                                      key={item.id}
                                      name={item.id}
                                      type="checkbox"
                                      title={item.id + "_" + item.ebname}
                                      value={item.id + "_" + item.ebname}
                                      checked={checked}
                                      onChange={this.handleChecked}
                                    />
                                    <label htmlFor={item.id}>
                                      {item.ebname}
                                    </label>
                                  </li>
                                </>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                      <div className="btn_grp">
                        <button
                          className="btn btn-reset"
                          tabIndex="0"
                          onClick={(e) => {
                            this.setState({
                              tempdblist: this.props.dblist,
                              keywords: "",
                            });
                            this.props.close(e);
                          }}>
                          {t("jumperrwd.common.cancel")}
                        </button>
                        <button
                          className="btn btn-submit"
                          tabIndex="0"
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({ keywords: "" });
                            this.props.handleConfirm(this.state.tempdblist);
                          }}>
                          {t("jumperrwd.common.confirm")}
                        </button>
                      </div>
                    </>
                  );
                }
                return "";
              }}
            </Query>
          </div>
        </div>
      </>
    );
  }
}

HyintDBListComp.defaultProps = {};

HyintDBListComp.propTypes = {
  t: PropTypes.func,
};
export default HyintDBListComp;
